<template>
    <div id="Profile">
        <div class="r-box"> 
            <div class="r-header">
                <h1>Account Information</h1>
            </div>
            <div class="r-body">
                <div class="y-inputField">
                    <div class="y-labelText">
                        Name
                    </div>
                    <div class="y-hasIcon-right y-md-size">
                        <input v-model="item.name" :class="error.name ? 'y-red-outline' : ''" disabled>
                    </div>
                    <div class="y-errorText y-red-text" :style="error.name ? 'opacity: 1 !important;': ''">
                        {{errorMsg.name}}
                    </div>
                </div>
                <div class="y-inputField">
                    <div class="y-labelText">
                        Username
                    </div>
                    <div class="y-hasIcon-right y-md-size">
                        <input v-model="item.username" :class="error.username ? 'y-red-outline' : ''" disabled>
                    </div>
                    <div class="y-errorText y-red-text" :style="error.username ? 'opacity: 1 !important;': ''">
                        {{errorMsg.username}}
                    </div>
                </div>
                <div class="y-inputField">
                    <div class="y-labelText">
                        Change Password
                    </div>
                    <div class="r-center-flex">
                        <div class="r-center-flex" style="margin-right: 15px">
                            <img 
                                @click="change_password = change_password == true ? false : true" 
                                :src="require(`@/assets/icons/checkmark/${change_password == true ? 'Active' : 'Inactive'}.svg`)" 
                                style="margin-right: 8px" >
                        </div>
                    </div>
                </div>
                <template v-if="change_password == true">
                        <div class="y-inputField">
                        <div class="y-labelText">
                            Current Password
                        </div>
                        <div class="y-hasIcon-right y-lr-size">
                            <input :type="showpassword == 'old' ? 'text' : 'password'" v-model="item.old_password"  :class="error.old_password ? 'y-red-outline' : ''" @keyup="error.old_password = false">
                            <div class="y-inputIcon r-center-flex">
                                <img :src="require(`@/assets/icons/login/${showpassword == 'old' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('old')">    
                            </div>
                        </div>
                        <div class="y-errorText y-red-text" :style="error.old_password ? 'opacity: 1 !important;': ''">
                            {{errorMsg.old_password}}
                        </div>
                    </div>
                    <div class="y-inputField">
                        <div class="y-labelText">
                            New Password
                        </div>
                        <div class="y-hasIcon-right y-lr-size">
                            <input :type="showpassword == 'new' ? 'text' : 'password'" 
                                v-model="item.new_password"  
                                :class="error.new_password ? 'y-red-outline' : ''"
                                @keyup="setTime()" 
                                @keydown="clearTime()" 
                                @keydown.tab="setTime()" 
                                @keyup.tab="clearTime()"
                            >
                            <div class="y-inputIcon r-center-flex">
                                <img :src="require(`@/assets/icons/login/${showpassword == 'new' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('new')">    
                            </div>
                        </div>
                        <div class="y-errorText y-red-text" 
                            :style="error.new_password ? 'opacity: 1 !important;': ''"
                        >
                            {{errorMsg.new_password}}
                        </div>
                        <div v-if="(item.new_password != null && item.new_password != '') || item.new_password_length" 
                            class="y-errorText y-red-text" 
                            :style="error.new_password_length ? 'opacity: 1 !important;': ''"
                        >
                            {{errorMsg.new_password_length}}
                        </div>
                        <div v-if="(item.new_password != null && item.new_password != '') || item.new_password_lower" 
                            class="y-errorText y-red-text" 
                            :style="error.new_password_lower ? 'opacity: 1 !important;': ''"
                        >
                            {{errorMsg.new_password_lower}}
                        </div>
                        <div v-if="(item.new_password != null && item.new_password != '') || item.new_password_upper" 
                            class="y-errorText y-red-text" 
                            :style="error.new_password_upper ? 'opacity: 1 !important;': ''"
                        >
                            {{errorMsg.new_password_upper}}
                        </div>
                    </div>
                    <div class="y-inputField">
                        <div class="y-labelText">
                            Confirm Password
                        </div>
                        <div class="y-hasIcon-right y-lr-size">
                            <input :type="showpassword == 'confirm' ? 'text' : 'password'" v-model="item.confirm_password"  :class="error.confirm_password ? 'y-red-outline' : ''" @keyup="error.confirm_password = false">
                            <div class="y-inputIcon r-center-flex">
                                <img :src="require(`@/assets/icons/login/${showpassword == 'confirm' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('confirm')">    
                            </div>
                        </div>
                        <div class="y-errorText y-red-text" :style="error.confirm_password ? 'opacity: 1 !important;': ''">
                            {{errorMsg.confirm_password}}
                        </div>
                    </div>
                </template>
            </div>
            <div class="r-footer">
                <div class="r-center-flex">
                    <button class="y-primary-btn y-hasspace-btn y-sm-size" style="margin-left: auto" @click.prevent="sendData()">
                        <span v-if="!loading">Save</span>
                        <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-else />   
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import validation from '@/mixins/function/validation'
export default {
    mixins: [
        common, 
        tableMixins,
        validation,
    ],
    components: {

    },
    async mounted() {
        await this.$store.restored
        this.$store.commit("SET_ACTIVETAB", "");
        var breadcrumb = [
            {
                name: "User Profile",
                link: {},
                current: true
            }
        ]
        this.$store.commit("SET_BREADCRUMB", breadcrumb);
        this.setItem(this.user);
    },
    computed: {
        ...mapGetters ({
            role: 'auth/getRole',
            item: 'auth/getItem',
            user: 'auth/getUser',
        }),
    },
    data: () => ({
        showpassword: false,
        loading: false,
        typingStatus: false,
        passwordTping: false,
        typingTimer: null,
        error: {
            name: null,
            username: null,
            old_password: null,
            new_password: null,
            new_password_length: null,
            new_password_upper: null,
            new_password_lower: null,
            confirm_password: null,
        },
        errorMsg: {
            name: "Tidak Boleh Kosong",
            username: "Tidak Boleh Kosong",
            old_password: "Tidak Boleh Kosong",
            new_password: "Tidak Boleh Kosong",
            new_password_length: "Password minimal 6 karakter",
            new_password_upper: "Password harus memiliki huruf besar",
            new_password_lower: "Password harus memiliki huruf kecil",
            confirm_password: "Tidak Boleh Kosong",
        },
        change_password: false,
    }),
    methods: {
        ...mapActions ({
            setItem: 'auth/setItem',
            update: 'auth/update',
        }),
        setShowPassword(value){
            if(this.showpassword == value){
                this.showpassword = null
            }else{
                this.showpassword = value
            }
        },
        async validation() {
            let field = ['name', 'username']
            if (this.change_password == true) {
                field.push('old_password')
                field.push('new_password')
                field.push('confirm_password')
            }
            return await this.validate(field, this.item)
        },
        setTime() {
            this.typingStatus = true
            this.passwordTping = true
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.validatePassword(), 2000);
        },
        clearTime() {
            clearTimeout(this.typingTimer);
        },
        validatePassword(){ 
            this.passwordTyping = false
            if (!/^(?=.*[a-z])/.test(this.item.new_password)) {
                this.error.new_password_lower = true
                this.errorMsg.new_password_lower = "Password harus memiliki huruf kecil"
            } else {
                this.error.new_password_lower = false
            }
            if (!/^(?=.*[A-Z])/.test(this.item.new_password)) {
                this.error.new_password_upper = true
                this.errorMsg.new_password_upper = "Password harus memiliki huruf besar"
            } else {
                this.error.new_password_upper = false
            }
            if (!/^[0-9a-zA-Z]{6,}$/.test(this.item.new_password)) {
                this.error.new_password_length = true
                this.errorMsg.new_password_length = "Password minimal 6 karakter"
            } else {
                this.error.new_password_length = false
            }
        },
        async sendData() {
            const errorCount = await this.validation()
            if (errorCount === 0) {
                if (this.item.confirm_password != this.item.new_password) {
                    this.error.new_password = true
                    this.errorMsg.new_password = "Password tidak sama"
                    this.errorMsg.confirm_password = "Password tidak sama"
                    this.error.confirm_password = true
                    return
                }
                if (this.item.old_password == this.item.new_password && this.item.old_password != null && this.item.new_password != null) {
                    this.error.new_password = true
                    this.errorMsg.new_password = "Password tidak boleh sama"
                    this.errorMsg.old_password = "Password tidak boleh sama"
                    this.error.old_password = true
                    return
                }
                this.loading = true
                this.item.change_password = this.change_password
                await this.update(this.item)
                this.loading = false
                const response = this.getResponse('auth')
                if (response.status == 1) {
                    this.item.old_password = null
                    this.item.new_password = null
                    this.item.confirm_password = null
                    this.change_password = false
                    this.showSnackbar ({
                        type: 'success',
                        text: response.msg
                    })
                } else {
                    this.showSnackbar ({
                        type:'error',
                        text: response.msg
                    })
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon Periksa Kembali'
                })
            }
        },
    }
}
</script>
<style lang="scss">
    #Profile{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        .r-center-flex{
            .y-inputField{
                flex: 1;
                padding: 0px 16px 0px 0px;
            }
        }
        .r-box{
            width: 100%;
            padding: 0px 0px 16px 16px;
            .y-inputField{
                padding: 0px 16px 0px 0px;
            }
        }
    }
</style>